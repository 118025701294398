import React from "react"
import { Image, Modal, Icon } from "semantic-ui-react"

const ImageModal = ({ img }) => (
  <Modal
    trigger={
      <div>
        <Image fluid src={img} />
        <div className="fader"></div>
        <div className="magnifier">
          <Icon name="search" />
        </div>
      </div>
    }
    centered={false}
    className="hd-modal"
  >
    <Modal.Content image>
      <div className="gallery-container">
        <div className="gallery">
          <Image fluid src={img} />
        </div>
        <div className="frame"></div>
      </div>
    </Modal.Content>
  </Modal>
)

export default ImageModal
